<template>
  <div class="filling-indicators">
    <div class="filling-indicators__header">
      <div class="filling-indicators__header-select">
        <div class="filling-indicators__header-select-item">
          <p>{{ $t('year') }}</p>
          <ui-select v-model="year" :options="getYears" @input="getRegions"/>
        </div>
        <div class="filling-indicators__header-select-item">
          <p>{{ $t('region') }}</p>
          <ui-select v-model="region" :options="dataRegions" @input="callIndicatorsApi"/>
        </div>
      </div>
      <div class="filling-indicators__header-right">
        <p class="filling-indicators__header-right-export" @click="exportIndicators"><img src="@/assets/svg/download-grey.svg" alt=""/>{{ $t('download-an-Excel-for-all-regions') }}</p>
        <div class="filling-indicators__header-right-download">
          <label :class="['filling-indicators__header-right-input', {disabled: !year.value || !region.id}]">
            <input @input="downloadExcel" type="file" ref="file" v-if="year.value && region.id">
            <p><img src="@/assets/svg/download-grey.svg" alt=""/>{{ $t('download-indicators') }}</p>
          </label>
          <p class="filling-indicators__header-right-delete" @click="deleteExcelData"><img src="@/assets/svg/icon-trash.svg" alt=""/>{{ $t('clear-indicators') }}</p>

        </div>

      </div>

    </div>

    <UiLoader v-model="loader"/>
    <UiNotification v-model="showNotification" :message="message" />
    <RegionIndicatorsTable
        v-if="!loader && region.name && getIndicators.length"
        :descriptionList="[
            {title: $t('code'), width: 80, paddingLeft: 10,},
            {title: $t('indicator'), width: 300},
            {title: $t('unit-of-measurement'), width: 160, paddingLeft: 0, textAlign: 'center'},
            {title: $t('value'), width: 160, paddingLeft: 0, textAlign: 'center'},
            {title: $t('source'), width: 200, paddingLeft: 0, textAlign: 'center'}
        ]"
        :data="getIndicators"
        @editItem="editItem"
        isFilling
        :isCodeVisible="true"
    />
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import RegionIndicatorsTable from "@/components/template/moderatorRegion/regionIndicators/RegionIndciatorsTable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";

export default {
  name: "FillingIndicators",
  components: {UiNotification, UiLoader, RegionIndicatorsTable, UiSelect},

  data () {
    return {
      year: {},
      region: {},
      loader: false,
      showNotification: false,
      message: ''
    }
  },

  computed: {
    ...mapGetters(['dataRegions', 'getIndicators', 'getYears'])
  },

  methods: {
    ...mapActions(['regions', 'indicators', 'addIndicatorsRegionValue', 'editIndicators', 'years', 'downloadIndicators', 'reqUpload', 'exportExcel']),
    ...mapMutations(['setDataRegions', 'setYears']),
    getRegions () {
      this.region = {}
      this.setDataRegions([])
      this.regions({skip: 0, take: 200, sort: 'name', order: 'ASC'}).then((res) => {
        this.setDataRegions(res.data)
      })
    },

    callIndicatorsApi() {
      this.loader = true
      this.indicators({year: this.year.value, regionId: this.region.id, type: 'Base'}).then(() => {
        this.loader = false
      })
    },

    editItem(data) {
      this.loader = true
      const indicatorPayload = {
        type: "Base",
        name: data.newInfo.name,
        year: this.year.value,
        ratio: data.itemInfo.ratio,
        ord: data.itemInfo.ord,
        code: data.newInfo.code,
        measureName: data.newInfo.measureName,
        dataType: data.itemInfo.dataType,
        rrGroupId: data.itemInfo.rrGroupId,
        rrSpecBlockId: data.itemInfo.rrSpecBlockId,
        algorithmId: data.itemInfo.algorithmId,
        isInversion: data.itemInfo.isInversion,
        isFederalDistrictBased: data.itemInfo.isFederalDistrictBased,
        params: data.itemInfo.params,
        id: data.itemInfo.id
      }

      const regionPayload = {
        regionId: this.region.id,
        year: this.year.value,
        rrSpecId: data.itemInfo.id,
        value: data.newInfo.regionValue,
        valueSource: data.newInfo.sourceVal
      }
      this.addIndicatorsRegionValue(regionPayload).then(() => {
        this.editIndicators(indicatorPayload).then(() => {
          setTimeout(() => {
            this.showNotification = true
            this.loader = false;
            this.message = this.$t('indicator-successfully-update')
            this.callIndicatorsApi({year: this.year.value, regionId: this.region.id, type: 'Base'})
          }, 500)
        })
      })
    },

    downloadExcel ({target}) {
      if(this.year.value && this.region.id) {
        this.loader = true
        const formData = new FormData();
        formData.append('file', target.files[0]);
        formData.append('type', 'document');
        formData.append('year', String(this.year.value));
        formData.append('regionId', String(this.region.id));

        this.downloadIndicators(formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
          this.$refs.file.value = null;
          this.callIndicatorsApi({ year: this.year.value, regionId: this.region.id, type: 'Base' });
        });
      }
    },
    exportIndicators () {
      this.exportExcel({year: this.year.value}).then((res) => {
        const link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.file;
        link.download = res.file;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    },
    deleteExcelData () {
      this.loader = true
      this.getIndicators.forEach((el, idx, arr) => {
        const indicatorPayload = {
          type: "Base",
          name: el.name,
          year: this.year.value,
          ratio: el.ratio,
          ord: el.ord,
          code: el.code,
          measureName: el.measureName,
          dataType: el.dataType,
          rrGroupId: el.rrGroupId,
          rrSpecBlockId: el.rrSpecBlockId,
          algorithmId: el.algorithmId,
          isInversion: el.isInversion,
          isFederalDistrictBased: el.isFederalDistrictBased,
          params: el.params,
          id: el.id
        }
        const regionPayload = {
          regionId: this.region.id,
          year: this.year.value,
          rrSpecId: el.id,
          value: null,
          valueSource: null
        }

        this.addIndicatorsRegionValue(regionPayload).then(() => {
          this.editIndicators(indicatorPayload).then(() => {
            if (idx === arr.length-1) {
              setTimeout(() => {
                this.showNotification = true
                this.loader = false;
                this.message = this.$t('indicator-successfully-update')
                this.callIndicatorsApi({year: this.year.value, regionId: this.region.id, type: 'Base'})
              }, 500)
            }
          })
        })
      })
    },

  },

  mounted () {
    this.setYears([])
    this.years().then((res) => {
      let data = res.map((elem) => {
        elem.name = elem.id
        elem.value = elem.id
        return elem
      })
      this.loader = false;
      this.setYears(data)
      this.year = data[data.length-1];
    })
    this.getRegions()
  }
}
</script>

<style lang="scss" scoped>
.filling-indicators {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    &-select {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
      background: #FFFFFF;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 20px;
      max-width: 433px;
      width: 100%;

      ::v-deep .ui-select {
        z-index: auto;
        max-width: 305px;

        &__label {
          display: none;
        }

        &__inner-text {
          font-family: "Gotham Pro Regular";
        }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        p {
          font-size: 16px;
          line-height: 19px;
          color: #1B1A1F;
          white-space: nowrap;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-export {
        cursor: pointer;
      }
      &-download {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9A9A9A;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      &-input {
        cursor: pointer;

        input {
          display: none;
        }

        p {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #9A9A9A;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &.disabled {
          cursor: not-allowed !important;
        }
      }

      &-delete {
        cursor: pointer;
      }
    }
  }


}
</style>
